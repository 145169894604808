<template>
	<div class="page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit">司机信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机电话" style="width:388px">
					<el-input class="el_inner_width" v-model="driver.tel" clearable>
						<el-button type="success" slot="append" @click="driver_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机信息" style="width:588px">
					<el-input class="el_inner_width" v-model="driver.info_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">货车信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车牌号" style="width:388px">
					<el-input class="el_inner_width" v-model="truck_info.truck_plate_num" :disabled="truck_info.status==2" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车辆信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_info.info_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49%">
					<div class="img_btn" @click="choose_img('dl_img')">
						<div v-if="!dl_img.src" class="btn">未上传</div>
						<img  v-else :src="dl_img.src" class="img">
					</div>
					<div class="bottom_text">行驶证</div>
				</div>
				<div class="el_form_item" style="width:49%;margin-left:1%">
					<div class="img_btn" @click="choose_img('tl_img')">
						<div v-if="!tl_img.src" class="btn">未上传</div>
						<img  v-else :src="tl_img.src" class="img">
					</div>
					<div class="bottom_text">道路运输证</div>
				</div>
			</div>
		</el-form>

		<div class="bottom_btns" v-if="truck_info.has_ser&&truck_info.status!=2">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {

				//司机信息
				driver:{
					
					//是否已经查找过
					has_ser:false,

					//司机手机
					tel:'',

					//司机编号
					driver_num:'',

					//司机信息
					info_text:'',
				},

				truck_info:{

					//是否已经查找过
					has_ser:false,

					//货车编号
					truck_inner_num:'',

					//审核状态
					status:'',

					//车牌号
					truck_plate_num:'',
					truck_plate_num_lock:'',

					//货车信息
					info_text:''
				},
				

				//行驶证
				dl_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'truckdl'
				},

				//道路运输证
				tl_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'trucktl'
				},

				//提交锁
				sub_swh:false
			}
		},
		created(){
			
			//初始化
			this.init();
		},
		methods: {

			//执行提交
			do_sub(){

				//行驶证
				if(!this.dl_img.is_upl&&this.dl_img.is_choose)return;

				//道路运输证
				if(!this.tl_img.is_upl&&this.tl_img.is_choose)return;

				if(this.sub_swh){
					return;
				}

				//上锁
				this.sub_swh=true

				//表单
				let form={
					mod:'truck',
					user_num:this.driver.driver_num,
					truck_plate_num:this.truck_info.truck_plate_num,
					dl_img:this.dl_img.key,
					tl_img:this.tl_img.key,
				}

				//此货车添加过->修改货车
				if(this.truck_info.truck_inner_num){
					form.ctr="truck_edit_by_admin"
					form.truck_inner_num=this.truck_info.truck_inner_num
				}else{
					form.ctr="truck_add_by_admin"
				}

				//调接口
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						
						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//表单初始化
						this.init();
					}
				});
			},

			//提交
			sub(){

				if(!(this.driver.has_ser&&this.truck_info.has_ser)){
					return;
				}

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:'提交中...'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'添加此货车",
					confirm:()=>{

						//货车数据处理
						// if(!this.$my.check.is_plate_num(this.truck_info.truck_plate_num)){
						// 	this.$my.other.msg({
						// 		type:'warning',
						// 		str:'车牌号不能为空'
						// 	});
						// 	return;
						// }

						//行驶证
						if(this.dl_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.dl_img.bucket,
								key: this.dl_img.key, //xxxxxx?v=1 或者 null
								file_path: this.dl_img.src,
								callback: (data) => {
									this.dl_img.is_upl = true;
									this.dl_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//道路运输证
						if(this.tl_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.tl_img.bucket,
								key: this.tl_img.key, //xxxxxx?v=1 或者 null
								file_path: this.tl_img.src,
								callback: (data) => {
									this.tl_img.is_upl = true;
									this.tl_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//用于补充
						this.do_sub();
					}
				});
			},

			//选择图片
			choose_img(img_name) {

				//未搜索不能修改
				if(!(this.truck_info.has_ser&&this.truck_info.status!=2)){
					return;
				}

				//取出相应对象
				let ctr_obj = this[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;
					}
				});
			},

			//货车查找
			truck_ser(){

				if(!this.driver.driver_num){
					this.$my.other.msg({
						type:'warning',
						str:'请先查询司机'
					});
					return;
				}

				// if(!this.$my.check.is_plate_num(this.truck_info.truck_plate_num)){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'缺少车牌号'
				// 	});
				// 	return;
				// }

				//读取已绑定货车
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						user_num:this.driver.driver_num,
						// truck_plate_num:this.truck_info.truck_plate_num
					},
					callback:(data)=>{

						//取出货车信息
						let truck_info=data.list[0]?data.list[0]:{};

						//已搜索
						this.truck_info.has_ser=true;

						//已搜索
						this.truck_info.truck_plate_num=truck_info.truck_plate_num;

						//货车编号
						this.truck_info.truck_inner_num=truck_info.truck_inner_num

						//车辆状态
						this.truck_info.status=truck_info.status
						switch(truck_info.status){
							case '1':this.truck_info.info_text="审核中";break;
							case '2':this.truck_info.info_text="审核通过";break;
							case '3':this.truck_info.info_text="审核未通过";break;
							default :this.truck_info.info_text="未添加";break;
						}

						//行驶证
						if(truck_info.dl_img){
							this.dl_img.key=truck_info.dl_img
							this.dl_img.src=this.$my.qiniu.make_src('truckdl',truck_info.dl_img)
						}else{
							this.dl_img.key=""
							this.dl_img.src=""
						}
						this.dl_img.is_choose=false
						this.dl_img.is_upl=false

						//道路运输证
						if(truck_info.tl_img){
							this.tl_img.key=truck_info.tl_img
							this.tl_img.src=this.$my.qiniu.make_src('trucktl',truck_info.tl_img)
						}else{
							this.tl_img.key=""
							this.tl_img.src=""
						}
						this.tl_img.is_choose=false
						this.tl_img.is_upl=false
						
						// //来源说明
						// this.truck_source.selected=''
						// for(var i in this.truck_source.list){

						// 	var truck_source_item=this.truck_source.list[i];

						// 	if(truck_source_item.db_text==truck_info.truck_source)this.truck_source.selected=parseInt(i)
						// }
						

						// //来源凭证
						// if(truck_info.truck_source_proof){
						// 	this.truck_source_proof.key=truck_info.truck_source_proof
						// 	this.truck_source_proof.src=this.$my.qiniu.make_src('trucksourceproof',truck_info.truck_source_proof)
						// }else{
						// 	this.truck_source_proof.key=""
						// 	this.truck_source_proof.src=""
						// }
						// this.truck_source_proof.is_choose=false
						// this.truck_source_proof.is_upl=false
					}
				});
			},

			//司机查找
			driver_ser(){

				if(!this.$my.check.is_tel(this.driver.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少司机电话'
					});
					return;
				}

				//读取司机信息
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.driver.tel,
					},
					callback:(data)=>{

						//取出司机数据
						let user_info=data.list[0];

						//未注册
						if(!user_info){
							this.$my.other.msg({
								type:'warning',
								str:'此用户未注册, 请先注册'
							});
							return;
						}

						//已搜索
						this.driver.has_ser=true;

						//司机编号
						this.driver.driver_num=user_info.user_num

						//实名状态字符串
						switch(user_info.real_status){
							case '1':user_info.real_status_text="未实名";break;
							case '2':user_info.real_status_text="已实名";break;
							case '3':user_info.real_status_text="实名失败";break;
						}

						//司机信息字符串
						this.driver.info_text=user_info.name+" / "+user_info.real_status_text
						
						//读取此人名下的车辆
						this.truck_ser()
					}
				});
			},

			//初始化
			init(){

				//未搜索
				this.driver.has_ser=false;

				//司机电话
				this.driver.tel=""

				//司机编号
				this.driver.driver_num=""

				//司机信息字符串
				this.driver.info_text=""

				//未搜索
				this.truck_info.has_ser=false;

				//车牌号
				this.truck_info.truck_plate_num=""
				this.truck_info.truck_plate_num_lock=""

				//货车信息
				this.truck_info.info_text=""

				//行驶证
				this.dl_img.key=""
				this.dl_img.src=""
				this.dl_img.is_choose=false
				this.dl_img.is_upl=false

				//道路运输证
				this.tl_img.key=""
				this.tl_img.src=""
				this.tl_img.is_choose=false
				this.tl_img.is_upl=false

				// //来源说明
				// this.truck_source.selected=""
				// this.truck_source.list=[]

				// //来源凭证
				// this.truck_source_proof.key=""
				// this.truck_source_proof.src=""
				// this.truck_source_proof.is_choose=false
				// this.truck_source_proof.is_upl=false

				//解除提交锁
				this.sub_swh=false

				// //读取可用来源说明
				// if(this.truck_source.list.length==0){
				// 	this.$my.net.req({
				// 		data:{
				// 			mod:'truck',
				// 			ctr:'get_truck_sources'
				// 		},
				// 		callback:(data)=>{

				// 			//置入数据
				// 			this.truck_source.list=data.list
				// 		}
				// 	});
				// }
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.page{
		background-color: #fff;
		padding:0 20px 20px 20px;
		border-radius: 10px;
		height: calc(100% - 20px);
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
		
</style>